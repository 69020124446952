<template>
  <div>
    <b-button
      v-if="!noBtn"
      variant="success"
      @click="toggleModal()"
    >
      <i class="uil uil-plus-circle mr-1"></i>
      {{ $t('buttons.add')}}
    </b-button>
    <b-modal
      v-model="isBlacklistModalActive"
      title="แบล็คลิสต์"
      hide-footer
    >
      <b-overlay :show="isFetching">
        <ValidationObserver
          ref="blacklistForm"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              label="เลือกประเภท"
              label-for="type"
            >
              <b-select
                id="type"
                v-model="blacklistForm.type"
              >
                <b-form-select-option
                  v-for="(otp, otpI) in options"
                  :key="otpI"
                  :value="otp.value"
                >{{ otp.label }} </b-form-select-option>
              </b-select>
            </b-form-group>
            <b-form-group
                label-cols-sm="12"
                label-cols-lg="3"
                :label="blacklistForm.type === 0 ? 'ชื่อบัญชี' : 'เลขบัญชี'"
                label-for="value"
            >
              <b-form-input
                  id="handySmsID"
                  v-model="blacklistForm.value"
              ></b-form-input>
            </b-form-group>
            <b-form-group
                label-cols-sm="12"
                label-cols-lg="3"
                label="คำอธิบาย"
                label-for="remark"
            >
              <b-form-textarea
                  id="remark"
                  v-model="blacklistForm.remark"
              ></b-form-textarea>
            </b-form-group>
            <div class="text-right">
              <b-button
                variant="light"
                class="mr-2"
                @click="onCloseModal"
              >
                {{ $t('buttons.cancel')}}
              </b-button>
              <b-overlay
                :show="isLoading"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('buttons.confirm')}}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    noBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // ACCOUNT_NAME = 0
      // ACCOUNT_NUMBER = 1 
      options: [
        { value: 0, label: 'ชื่อบัญชี'},
        { value: 1, label: 'เลขบัญชี'},
      ],
      isBlacklistModalActive: false,
      blacklistForm: {
        type: 0,
        value: '',
        remark: ''
      },
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.blacklist.isFetchingBlacklist,
      isLoading: (state) => state.blacklist.isAddingBlacklist || state.blacklist.isUpdatingBlacklist
    }),
    ...mapGetters(['blacklistDetail']),
  },
  watch: {
    blacklistDetail(val) {
      if (val) {
        this.setData()
      }
    },
    id(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchBlacklist','createBlacklist', 'updateBlacklist']),
    fetchData() {
      if(this.id) {
        this.fetchBlacklist(this.id)
      }
    },
    setData() {
      if(this.blacklistDetail && this.blacklistDetail.id){
        const data = JSON.parse(JSON.stringify(this.blacklistDetail))
        this.blacklistForm = { ...data }
      }
    },
    toggleModal() {
      this.isBlacklistModalActive = !this.isBlacklistModalActive
    },
    onCloseModal() {
      this.isBlacklistModalActive = false
    },
    async onSubmit() {
      if(this.blacklistForm.id){
        await this.updateBlacklist({ id: this.blacklistForm.id, data: this.blacklistForm})
      } else {
        await this.createBlacklist(this.blacklistForm)
      }
      this.onCloseModal()
      this.blacklistForm = {
        type: '0',
        value: '',
        remark: ''
      }
    },
  },
}
</script>